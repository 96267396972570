<template>
  <div class="plan-editor">
    <div class="editor-header">
      <h2>{{ isNewPlan ? 'Create New Plan' : 'Edit Plan' }}</h2>
      <div class="editor-status">
        <span v-if="hasUnsavedChanges" class="unsaved-indicator">Unsaved changes</span>
        <span v-else-if="!isNewPlan" class="saved-indicator">All changes saved</span>
      </div>
    </div>
    
    <div class="editor-form">
      <div class="form-group">
        <label for="plan-title">Title</label>
        <InputText 
          id="plan-title" 
          v-model="planTitle" 
          class="w-full" 
          placeholder="Enter a descriptive title for your plan"
          @input="checkForChanges"
        />
      </div>
      
      <div class="form-group">
        <label for="plan-content">Content</label>
        <div class="content-editor-container">
          <div class="editor-toolbar">
            <Button 
              icon="pi pi-list" 
              v-tooltip="'Add numbered step'" 
              @click="insertNumberedStep" 
              class="p-button-text p-button-sm"
            />
            <Button 
              icon="pi pi-check-square" 
              v-tooltip="'Add checkbox item'" 
              @click="insertCheckboxItem" 
              class="p-button-text p-button-sm"
            />
            <div class="toolbar-divider"></div>
            <Button 
              icon="pi pi-eye" 
              v-tooltip="'Toggle preview'" 
              @click="togglePreview" 
              :class="{'p-button-text p-button-sm': true, 'p-button-outlined': showPreview}"
            />
          </div>
          
          <div class="editor-content">
            <TextArea 
              v-show="!showPreview"
              id="plan-content" 
              v-model="planContent" 
              class="w-full" 
              rows="15" 
              placeholder="Type your plan here. Use numbered steps for tasks that need to be completed in order."
              @input="checkForChanges"
              ref="contentTextarea"
            />
            
            <div v-show="showPreview" class="content-preview">
              <div v-for="(line, index) in contentLines" :key="index" class="preview-line">
                {{ line }}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Voice Assistant Section -->
      <div class="voice-assistant-section">
        <div class="voice-assistant-header">
          <h3>Voice Assistant</h3>
          <div class="voice-status">
            <span v-if="isVoiceActive" class="voice-active">Voice assistant active</span>
          </div>
        </div>
        
        <div class="voice-assistant-controls">
          <Button 
            :label="isVoiceActive ? 'Stop Voice Assistant' : 'Start Voice Assistant'" 
            :icon="isVoiceActive ? 'pi pi-microphone-slash' : 'pi pi-microphone'" 
            :class="{'p-button-success': !isVoiceActive, 'p-button-danger': isVoiceActive}"
            @click="toggleVoiceAssistant" 
            :disabled="!apiKey"
          />
          <Button 
            v-if="!apiKey"
            icon="pi pi-key" 
            label="Set API Key" 
            @click="showApiKeyModal" 
            class="p-button-secondary"
          />
        </div>
        
        <div v-if="isVoiceActive" class="voice-assistant-feedback">
          <Card>
            <template #title>Voice Assistant</template>
            <template #content>
              <div class="voice-feedback-content">
                <p v-if="voiceFeedback" class="voice-feedback">{{ voiceFeedback }}</p>
                <p v-else class="voice-placeholder">Speak to add steps to your plan. The assistant will help you create detailed steps.</p>
              </div>
            </template>
          </Card>
        </div>
      </div>
      
      <div class="editor-tips">
        <h3>Tips for creating effective plans:</h3>
        <ul>
          <li>Start each step with a number (e.g., "1. First step")</li>
          <li>Keep steps clear and concise</li>
          <li>Include all necessary details for each step</li>
          <li>Use consistent formatting throughout your plan</li>
          <li>Use the voice assistant to dictate your plan steps</li>
        </ul>
      </div>
      
      <div class="editor-actions">
        <Button 
          label="Save" 
          icon="pi pi-save" 
          @click="savePlan" 
          class="p-button-success" 
          :disabled="!hasUnsavedChanges && !isNewPlan"
        />
        <Button 
          label="Cancel" 
          icon="pi pi-times" 
          @click="confirmCancel" 
          class="p-button-secondary" 
        />
      </div>
    </div>
    
    <Dialog v-model:visible="cancelDialogVisible" header="Unsaved Changes" :style="{width: '450px'}">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle" style="font-size: 2rem; color: var(--yellow-500);"></i>
        <span>You have unsaved changes. Are you sure you want to discard them?</span>
      </div>
      <template #footer>
        <Button label="No, Keep Editing" icon="pi pi-times" @click="cancelDialogVisible = false" class="p-button-text"/>
        <Button label="Yes, Discard" icon="pi pi-check" @click="discardChanges" class="p-button-danger" />
      </template>
    </Dialog>
    
    <Dialog v-model:visible="isApiKeyModalVisible" header="Enter OpenAI API Key" height="700px"
      :style="{ padding: '20px' }" :closable="true" :showCloseIcon="true">
      <div style="margin-bottom: 1em">
        <InputText v-model="apiKey" placeholder="Paste Key Here" />
      </div>
      <Button label="Save" @click="saveApiKey" class="p-button-success" />
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, nextTick } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import Card from 'primevue/card';
import { useToast } from 'primevue/usetoast';
import RealtimeConnection from "@/lib/RealtimeConnection.js";

const props = defineProps({
  plan: {
    type: Object,
    default: () => ({
      id: null,
      title: '',
      content: '',
      createdAt: null
    })
  }
});

const emit = defineEmits(['save', 'cancel']);
const toast = useToast();

const planTitle = ref('');
const planContent = ref('');
const originalTitle = ref('');
const originalContent = ref('');
const showPreview = ref(false);
const cancelDialogVisible = ref(false);
const contentTextarea = ref(null);

// Voice assistant related refs
const isVoiceActive = ref(false);
const voiceFeedback = ref('');
const realtimeConnection = ref(null);
const isApiKeyModalVisible = ref(false);
const apiKey = ref(localStorage.getItem("openaiApiKey"));
const currentStepBeingClarified = ref(null); // Track which step is being clarified
const lastAddedStepNumber = ref(null); // Track the last added step number

const isNewPlan = computed(() => !props.plan.id);

const hasUnsavedChanges = computed(() => {
  return planTitle.value !== originalTitle.value || 
         planContent.value !== originalContent.value;
});

const contentLines = computed(() => {
  return planContent.value.split('\n');
});

onMounted(() => {
  // Initialize form with plan data
  planTitle.value = props.plan.title || '';
  planContent.value = props.plan.content || '';
  
  // Store original values to detect changes
  originalTitle.value = planTitle.value;
  originalContent.value = planContent.value;
});

// Watch for changes in the plan prop
watch(() => props.plan, (newPlan) => {
  planTitle.value = newPlan.title || '';
  planContent.value = newPlan.content || '';
  
  // Update original values
  originalTitle.value = planTitle.value;
  originalContent.value = planContent.value;
}, { deep: true });

const checkForChanges = () => {
  // This function is called on input events to update the unsaved changes status
  // The actual check is done in the computed property
};

const togglePreview = () => {
  showPreview.value = !showPreview.value;
};

const insertNumberedStep = () => {
  if (!contentTextarea.value) return;
  
  const textarea = contentTextarea.value.$el;
  const cursorPos = textarea.selectionStart;
  const textBefore = planContent.value.substring(0, cursorPos);
  const textAfter = planContent.value.substring(cursorPos);
  
  // Count existing numbered steps to determine the next number
  const lines = planContent.value.split('\n');
  let nextNumber = 1;
  
  for (const line of lines) {
    const match = line.match(/^(\d+)\./);
    if (match) {
      const num = parseInt(match[1]);
      if (num >= nextNumber) {
        nextNumber = num + 1;
      }
    }
  }
  
  // Insert new numbered step
  const newLine = (textBefore.endsWith('\n') || textBefore === '' ? '' : '\n') + 
                 `${nextNumber}. ` + 
                 (textAfter.startsWith('\n') ? '' : '\n');
  
  planContent.value = textBefore + newLine + textAfter;
  checkForChanges();
  
  // Set focus back to textarea and position cursor
  nextTick(() => {
    if (contentTextarea.value) {
      const newCursorPos = textBefore.length + newLine.length - (textAfter.startsWith('\n') ? 0 : 1);
      contentTextarea.value.$el.focus();
      contentTextarea.value.$el.setSelectionRange(newCursorPos, newCursorPos);
    }
  });
};

const insertCheckboxItem = () => {
  if (!contentTextarea.value) return;
  
  const textarea = contentTextarea.value.$el;
  const cursorPos = textarea.selectionStart;
  const textBefore = planContent.value.substring(0, cursorPos);
  const textAfter = planContent.value.substring(cursorPos);
  
  // Insert new checkbox item
  const newLine = (textBefore.endsWith('\n') || textBefore === '' ? '' : '\n') + 
                 '[ ] ' + 
                 (textAfter.startsWith('\n') ? '' : '\n');
  
  planContent.value = textBefore + newLine + textAfter;
  checkForChanges();
  
  // Set focus back to textarea and position cursor
  nextTick(() => {
    if (contentTextarea.value) {
      const newCursorPos = textBefore.length + newLine.length - (textAfter.startsWith('\n') ? 0 : 1);
      contentTextarea.value.$el.focus();
      contentTextarea.value.$el.setSelectionRange(newCursorPos, newCursorPos);
    }
  });
};

// Voice assistant functions
const toggleVoiceAssistant = async () => {
  if (isVoiceActive.value) {
    stopVoiceAssistant();
  } else {
    startVoiceAssistant();
  }
};

const startVoiceAssistant = async () => {
  if (!apiKey.value) {
    showApiKeyModal();
    return;
  }
  
  try {
    // Initialize RealtimeConnection
    realtimeConnection.value = new RealtimeConnection({
      apiKey: apiKey.value,
      onAddStep: handleAddStep,
      onAskClarification: handleAskClarification,
      handleConnectionEstablished: handleConnectionEstablished,
      onError: handleRealtimeError
    });

    await realtimeConnection.value.init();
    isVoiceActive.value = true;
    
    toast.add({
      severity: "success",
      summary: "Voice Assistant Started",
      detail: "You can now speak to add steps to your plan.",
      life: 3000,
    });
  } catch (error) {
    console.error("Error starting voice assistant:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error.message,
      life: 3000,
    });
  }
};

const stopVoiceAssistant = () => {
  if (realtimeConnection.value) {
    realtimeConnection.value.close();
    realtimeConnection.value = null;
  }
  
  isVoiceActive.value = false;
  voiceFeedback.value = '';
  
  toast.add({
    severity: "info",
    summary: "Voice Assistant Stopped",
    detail: "Voice assistant has been turned off.",
    life: 3000,
  });
};

const handleConnectionEstablished = () => {
  // Add tools for the voice assistant
  addVoiceAssistantTools();
  
  // Send initial context to the voice assistant
  const initialContext = `You are a helpful assistant that will help the user create a detailed plan. 
  The user will speak steps for their plan, and you should help them make these steps clear and detailed.
  
  IMPORTANT WORKFLOW:
  1. When the user speaks a step, immediately add it to the plan using the add_step function.
  2. If the step is vague or missing important details, ask follow-up questions to clarify using the ask_clarification function.
  3. When the user responds to your clarification question, their response will be used to update the existing step.
  
  For example:
  - User: "Add butter to the dish"
  - You: Use add_step to add "Add butter to the dish" to the plan, then use ask_clarification to ask "How much butter should be added?"
  - User: "Two tablespoons"
  - You: Use add_step again, and the system will update the existing step to "Add butter to the dish Two tablespoons"
  
  Always add the step first, then ask for clarification if needed. This ensures we capture all user input.
  
  Current plan title: ${planTitle.value}
  Current plan content: ${planContent.value || "No steps yet."}`;
  
  realtimeConnection.value.addToConversation(initialContext);
  
  toast.add({
    severity: "success",
    summary: "Voice Assistant Connected",
    detail: "Voice assistant is ready to help you create your plan.",
    life: 3000,
  });
};

const handleRealtimeError = (error) => {
  console.error("Voice Assistant Error:", error);
  toast.add({
    severity: "error",
    summary: "Voice Assistant Error",
    detail: error.message || "An error occurred with the voice assistant.",
    life: 5000,
  });
  
  // Stop the voice assistant on error
  stopVoiceAssistant();
};

const addVoiceAssistantTools = () => {
  if (!realtimeConnection.value) return;
  
  const sessionUpdateEvent = {
    type: "session.update",
    session: {
      tools: [
        {
          type: "function",
          name: "add_step",
          description: "Adds a new step to the plan.",
          parameters: {
            type: "object",
            properties: {
              step: {
                type: "string",
                description: "The step to add to the plan."
              },
              position: {
                type: "string",
                enum: ["start", "end", "after_current"],
                description: "Where to add the step in the plan."
              }
            },
            required: ["step"]
          }
        },
        {
          type: "function",
          name: "ask_clarification",
          description: "Ask the user for clarification about a step.",
          parameters: {
            type: "object",
            properties: {
              question: {
                type: "string",
                description: "The question to ask the user."
              }
            },
            required: ["question"]
          }
        }
      ]
    }
  };
  
  realtimeConnection.value.sendEvent(sessionUpdateEvent);
};

const handleAddStep = (action) => {
  if (!action || !action.step) return;
  
  // Get the step text
  const stepText = action.step.trim();
  if (!stepText) return;
  
  // Check if we're clarifying an existing step
  if (currentStepBeingClarified.value !== null) {
    // Update the existing step with the clarification
    updateStepWithClarification(currentStepBeingClarified.value, stepText);
    
    // Reset the clarification state
    currentStepBeingClarified.value = null;
    
    // Update the voice feedback
    voiceFeedback.value = `Updated step with clarification: ${stepText}`;
    
    // Mark the plan as changed
    checkForChanges();
    return;
  }
  
  // Determine the position to add the step
  const position = action.position || "end";
  
  // Count existing numbered steps to determine the next number
  const lines = planContent.value.split('\n').filter(line => line.trim() !== '');
  let nextNumber = 1;
  
  for (const line of lines) {
    const match = line.match(/^(\d+)\./);
    if (match) {
      const num = parseInt(match[1]);
      if (num >= nextNumber) {
        nextNumber = num + 1;
      }
    }
  }
  
  // Format the step with a number
  let formattedStep = stepText;
  if (!formattedStep.match(/^\d+\./)) {
    formattedStep = `${nextNumber}. ${formattedStep}`;
  }
  
  // Extract the step number for tracking
  const stepNumberMatch = formattedStep.match(/^(\d+)\./);
  if (stepNumberMatch) {
    lastAddedStepNumber.value = parseInt(stepNumberMatch[1]);
  }
  
  // Add the step to the plan content
  if (position === "start") {
    planContent.value = formattedStep + '\n' + planContent.value;
  } else if (position === "after_current") {
    // Find the last completed step and add after it
    // For simplicity, we'll just add it at the end for now
    planContent.value = planContent.value + '\n' + formattedStep;
  } else {
    // Default to adding at the end
    planContent.value = planContent.value + '\n' + formattedStep;
  }
  
  // Mark the plan as changed
  checkForChanges();
  
  // Update the voice feedback
  voiceFeedback.value = `Added step: ${formattedStep}`;
};

// Function to update a step with clarification
const updateStepWithClarification = (stepNumber, clarificationText) => {
  const lines = planContent.value.split('\n');
  
  // Find the line with the specified step number
  for (let i = 0; i < lines.length; i++) {
    const match = lines[i].match(/^(\d+)\./);
    if (match && parseInt(match[1]) === stepNumber) {
      // Remove any existing clarification marker
      let updatedLine = lines[i].replace(/\s*\[Clarifying:.*?\]\s*$/, '');
      
      // Update the line with the clarification
      lines[i] = updatedLine + ' ' + clarificationText;
      
      // Update the plan content
      planContent.value = lines.join('\n');
      return;
    }
  }
};

const handleAskClarification = (action) => {
  if (!action || !action.question) return;
  
  // Set the current step being clarified to the last added step
  currentStepBeingClarified.value = lastAddedStepNumber.value;
  
  // Update the voice feedback with the question
  voiceFeedback.value = action.question;
  
  // Append the question to the last step in the plan to show it's being clarified
  if (lastAddedStepNumber.value !== null) {
    const lines = planContent.value.split('\n');
    
    // Find the line with the last added step number
    for (let i = 0; i < lines.length; i++) {
      const match = lines[i].match(/^(\d+)\./);
      if (match && parseInt(match[1]) === lastAddedStepNumber.value) {
        // Add a visual indicator that this step is being clarified
        lines[i] = lines[i] + ' [Clarifying: ' + action.question + ']';
        
        // Update the plan content
        planContent.value = lines.join('\n');
        break;
      }
    }
  }
};

const showApiKeyModal = () => {
  isApiKeyModalVisible.value = true;
};

const saveApiKey = () => {
  localStorage.setItem("openaiApiKey", apiKey.value);
  isApiKeyModalVisible.value = false;
  toast.add({
    severity: "success",
    summary: "API Key Saved",
    detail: "Your API key has been saved successfully.",
    life: 3000,
  });
};

const savePlan = () => {
  if (!planTitle.value.trim()) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Plan title is required',
      life: 3000
    });
    return;
  }

  if (!planContent.value.trim()) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Plan content is required',
      life: 3000
    });
    return;
  }

  const updatedPlan = {
    ...props.plan,
    title: planTitle.value.trim(),
    content: planContent.value.trim(),
    updatedAt: new Date().toISOString()
  };

  // If it's a new plan, generate an ID
  if (!updatedPlan.id) {
    updatedPlan.id = 'plan_' + Date.now();
    updatedPlan.createdAt = updatedPlan.updatedAt;
  }

  emit('save', updatedPlan);
  
  // Update original values to reflect saved state
  originalTitle.value = planTitle.value;
  originalContent.value = planContent.value;
  
  toast.add({
    severity: 'success',
    summary: 'Success',
    detail: 'Plan saved successfully',
    life: 3000
  });
};

const confirmCancel = () => {
  if (hasUnsavedChanges.value) {
    cancelDialogVisible.value = true;
  } else {
    emit('cancel');
  }
};

const discardChanges = () => {
  cancelDialogVisible.value = false;
  emit('cancel');
};
</script>

<style scoped>
.plan-editor {
  width: 100%;
  margin: 0 auto;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.editor-status {
  font-size: 0.9rem;
}

.unsaved-indicator {
  color: var(--orange-500);
  font-style: italic;
}

.saved-indicator {
  color: var(--green-500);
}

.editor-form {
  margin-top: 1rem;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.content-editor-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.editor-toolbar {
  display: flex;
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.toolbar-divider {
  width: 1px;
  background-color: #ddd;
  margin: 0 0.5rem;
}

.editor-content {
  min-height: 300px;
  width: 100%;
}

.editor-content :deep(.p-inputtextarea) {
  width: 100%;
  min-height: 300px;
  resize: vertical;
}

.content-preview {
  padding: 1rem;
  min-height: 300px;
  background-color: #f9f9f9;
  white-space: pre-wrap;
  width: 100%;
}

.preview-line {
  margin-bottom: 0.5rem;
}

/* Voice Assistant Styles */
.voice-assistant-section {
  margin-bottom: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  background-color: #f9f9ff;
}

.voice-assistant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.voice-assistant-header h3 {
  margin: 0;
  font-size: 1.1rem;
}

.voice-status {
  font-size: 0.9rem;
}

.voice-active {
  color: var(--green-600);
  font-weight: bold;
}

.voice-assistant-controls {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.voice-assistant-feedback {
  margin-top: 1rem;
}

.voice-feedback-content {
  min-height: 80px;
}

.voice-feedback {
  font-weight: 500;
  color: var(--text-color);
}

.voice-placeholder {
  color: var(--text-color-secondary);
  font-style: italic;
}

.editor-tips {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.editor-tips h3 {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.editor-tips ul {
  margin: 0;
  padding-left: 1.5rem;
}

.editor-tips li {
  margin-bottom: 0.25rem;
}

.editor-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
}
</style>
