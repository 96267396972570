<template>
  <div class="plan-viewer">
    <div class="video-feeds">
      <div class="camera-feed">
        <Card>
          <template #title>Live Camera Feed</template>
          <template #content><video ref="videoElement" autoplay playsinline class="video-element"></video></template>
        </Card>
      </div>
      <div class="current-image">
        <Card>
          <template #title>Image in Analysis</template>
          <template #content>
            <img v-if="capturedImage" :src="capturedImage" alt="Captured Image" class="processed-image" />
            <div v-else class="placeholder">No image</div>
          </template>
        </Card>
      </div>
    </div>
    
    <div class="camera-buttons">
      <Toolbar>
        <template #start>
          <div class="flex items-center gap-2">
            <Button v-tooltip="'Starts a session'" label="Start Session" icon="pi pi-video" @click="startSession"
              class="p-button-success" :disabled="isCameraActive" />
            <Button v-tooltip="'Stops session'" label="Stop" icon="pi pi-stop" @click="stopSession"
              class="p-button-danger" :disabled="!isCameraActive" />
            <Button v-if="cameraOptions?.length > 1" label="Select Camera" icon="pi pi-camera"
              @click="showCameraSelector" class="p-button-help" :disabled="isCameraActive" />
            <Button icon="pi pi-camera" v-tooltip="'Change Camera Facing'" @click="toggleCameraFacing"
              class="p-button-help" />
          </div>
        </template>

        <template #end>
          <Button v-tooltip="'Capture & Analyze'" label="Capture" icon="pi pi-check-square"
            @click="captureAndAnalyzeImage" class="p-button-info"
            :disabled="!isCameraActive || isContinuousActive" />
          <Button v-tooltip="'Continuous Analysis'" label="Continuous" icon="pi pi-play-circle"
            @click="toggleContinuousAnalysis" :class="{
              'p-button-success': isContinuousActive,
              'p-button-warning': !isContinuousActive,
            }" :disabled="!isCameraActive || isContinuousActive" />
          <Button :label="enforcePlanOrder ? 'Enforcing Order' : 'Ignoring Order'" icon="pi pi-sort-amount-down-alt"
            :class="{
              'p-button-success': enforcePlanOrder,
              'p-button-warning': !enforcePlanOrder,
            }" @click="toggleEnforcePlanOrder" />
          <Button icon="pi pi-key" class="api-key-button" @click="showApiKeyModal" />
        </template>
      </Toolbar>
    </div>
    
    <div class="plan-display-section">
      <h2>{{ currentPlan.title }}</h2>
      <div class="plan-display" ref="planDisplayArea">
        <div v-for="(line, index) in lineByLinePlan" :key="index" :class="{ 'completed-step': line.isCompleted }"
          :id="'plan-line-' + index">
          {{ line.text }}
        </div>
      </div>
      <Toolbar>
        <template #start>
          <div class="flex items-center gap-4">
            <Button label="Back to Plans" icon="pi pi-arrow-left" @click="goBackToPlans" />
            <Button label="Clear Actions" @click="clearCompletedActions" class="p-button-danger"
              :disabled="shouldDisableClearActions" />
          </div>
        </template>
      </Toolbar>
    </div>
    
    <div class="analysis-section" ref="analysisSection">
      <Card>
        <template #title>Debug</template>
        <template #content>
          <div v-if="isLoading" class="loading-section">
            <span>Loading...</span>
          </div>
          <div v-if="analysis">
            <p>{{ analysis }}</p>
          </div>
          <div v-if="!analysis && !isLoading" class="placeholder">
            Analysis will appear here...
          </div>
        </template>
      </Card>
    </div>
    
    <Dialog v-model:visible="isCameraSelectorVisible" style="width: 300px" header="Select Camera">
      <Dropdown :options="cameraOptions" optionLabel="label" placeholder="Select a Camera" v-model="selectedCamera"
        @change="changeCamera" />
    </Dialog>
    
    <Dialog v-model:visible="isApiKeyModalVisible" header="Enter OpenAI API Key" height="700px"
      :style="{ padding: '20px' }" :closable="true" :showCloseIcon="true">
      <div style="margin-bottom: 1em">
        <InputText v-model="apiKey" placeholder="Paste Key Here" />
      </div>
      <Button label="Save" @click="saveApiKey" class="p-button-success" />
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, nextTick, onUnmounted, watch } from "vue";
import { useToast } from "primevue/usetoast";
import Button from "primevue/button";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Toolbar from "primevue/toolbar";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Tesseract from "tesseract.js";
import RealtimeConnection from "@/lib/RealtimeConnection.js";

const props = defineProps({
  currentPlan: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['back-to-plans']);

const toast = useToast();
const videoElement = ref(null);
const capturedImage = ref("");
const cameraOptions = ref([]);
const selectedCamera = ref(null);
const analysis = ref("");
const planActionsModel = ref({});
const analysisSection = ref(null);
const planDisplayArea = ref(null);
const isLoading = ref(false);
const isCameraActive = ref(false);
const isContinuousActive = ref(false);
const isApiKeyModalVisible = ref(false);
const enforcePlanOrder = ref(false);
const cameraFacing = ref("environment");
const apiKey = ref(localStorage.getItem("openaiApiKey"));
const realtimeConnection = ref(null);
const isRealtimeConnected = ref(false);
const realtimeError = ref(null);
let stream = null;

// Camera selector dialog
const isCameraSelectorVisible = ref(false);
const showCameraSelector = () => {
  isCameraSelectorVisible.value = true;
};

// Constants
const CONTINUOUS_IMAGE_ANALYSIS_INTERVAL = 2000;
const imageAnalysisInterval = ref(null);

onMounted(async () => {
  // Load plan actions from local storage
  loadPlanActions();
  
  // Initialize camera options
  const devices = await navigator.mediaDevices.enumerateDevices();
  console.debug(`📸 Found devices`, devices);
  let foundCameras = 0;
  cameraOptions.value = devices
    .filter((device) => device.kind === "videoinput")
    .map((device) => {
      const label = device.label.split(" ")[0] + " " + ++foundCameras;
      return { label, value: device.deviceId };
    });
  selectedCamera.value = cameraOptions.value[0];
  
  // Check for API key
  if (!apiKey.value) {
    isApiKeyModalVisible.value = true;
  }
});

onUnmounted(() => {
  if (realtimeConnection.value) {
    realtimeConnection.value.close();
  }
  
  // Stop camera if active
  if (stream) {
    stopStreamingMedia();
  }
});

// Watch for changes in the current plan
watch(() => props.currentPlan, () => {
  loadPlanActions();
}, { deep: true });

const loadPlanActions = () => {
  // Get plan actions from local storage
  const planId = props.currentPlan.id;
  const storedActions = localStorage.getItem(`planActions-${planId}`);
  
  if (storedActions) {
    planActionsModel.value = JSON.parse(storedActions);
  } else {
    planActionsModel.value = {};
    savePlanActions();
  }
};

const savePlanActions = () => {
  const planId = props.currentPlan.id;
  localStorage.setItem(`planActions-${planId}`, JSON.stringify(planActionsModel.value));
};

const lineByLinePlan = computed(() =>
  props.currentPlan.content.split("\n").map((text, index) => {
    return {
      text,
      id: index,
      isCompleted: isStepCompleted(index),
    };
  })
);

function isStepCompleted(index) {
  return planActionsModel.value[index] !== undefined;
}

const shouldDisableClearActions = computed(() => {
  return Object.keys(planActionsModel.value).length === 0;
});

const saveApiKey = () => {
  localStorage.setItem("openaiApiKey", apiKey.value);
  isApiKeyModalVisible.value = false;
  toast.add({
    severity: "success",
    summary: "API Key Saved",
    detail: "Your API key has been saved successfully.",
    life: 3000,
  });
};

const showApiKeyModal = () => {
  isApiKeyModalVisible.value = true;
};

const continuousAnalysisLoop = async () => {
  if (!isContinuousActive.value) return; // Exit if the loop should no longer run
  await captureAndAnalyzeImage();
  setTimeout(continuousAnalysisLoop, CONTINUOUS_IMAGE_ANALYSIS_INTERVAL);
};

const toggleContinuousAnalysis = () => {
  isContinuousActive.value = !isContinuousActive.value;
  if (isContinuousActive.value) {
    continuousAnalysisLoop(); // Start the loop
  }
};

const changeCamera = async () => {
  if (stream) {
    stopStreamingMedia();
  }
  startCamera();
};

function clearCompletedActions() {
  planActionsModel.value = {};
  savePlanActions();
  analysis.value = '';
  if (realtimeConnection.value) {
    realtimeConnection.value.askForResponse(`The user has cleared their actions, so all actions are ready to be reworked.`);
  }
}

function toggleEnforcePlanOrder() {
  enforcePlanOrder.value = !enforcePlanOrder.value;
}

function toggleCameraFacing() {
  cameraFacing.value = cameraFacing.value === "user" ? "environment" : "user";
  stopCamera();
  startCamera();
}

const startSession = async () => {
  await startCamera();

  // Initialize RealtimeConnection
  realtimeConnection.value = new RealtimeConnection({
    apiKey: apiKey.value,
    onCompleteSteps: completeSteps,
    onClearAll: clearCompletedActions,
    onClearSpecificSteps: clearSpecificActions,
    handleConnectionEstablished,
    handleRealtimeError
  });

  await realtimeConnection.value.init();
};

const startCamera = async () => {
  try {
    const constraints = {
      video: { deviceId: selectedCamera.value?.value, facingMode: cameraFacing.value },
    };
    stream = await navigator.mediaDevices.getUserMedia(constraints);
    videoElement.value.srcObject = stream;
    isCameraActive.value = true;
    toast.add({ severity: "success", summary: "Camera Started", life: 1000 });
  } catch (error) {
    console.error("Error starting camera:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error.message,
      life: 1000,
    });
  }
};

const stopSession = () => {
  stopCamera();

  if (realtimeConnection.value) {
    realtimeConnection.value.close();
    realtimeConnection.value = null;
    isRealtimeConnected.value = false;
    toast.add({
      severity: "warn",
      summary: "Realtime Disconnected",
      detail: "Disconnected from OpenAI WebRTC API.",
      life: 3000,
    });
  }
};

const stopCamera = () => {
  clearInterval(imageAnalysisInterval.value);
  stopStreamingMedia();
  isContinuousActive.value = false;
  isLoading.value = false;
  toast.add({
    severity: "success",
    summary: "Camera & Analysis Stopped",
    life: 1000,
  });
};

const stopStreamingMedia = () => {
  if (stream) {
    stream.getTracks().forEach((track) => track.stop());
    isCameraActive.value = false;
  }
};

const extractTextFromImage = async () => {
  try {
    const { data } = await Tesseract.recognize(capturedImage.value, "eng", {
      logger: (m) => console.log(m),
      tessedit_char_whitelist:
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      tessedit_pageseg_mode: 6,
    });
    // Filter out results with low confidence
    const highConfidenceText = data.words
      .filter((word) => word.confidence > 40)
      .map((word) => word.text)
      .join(" ");
    console.debug("📝 All words:", data.words);
    console.debug("📝 High Confidence Extracted Text:", highConfidenceText);
    return highConfidenceText;
  } catch (error) {
    console.error("🛑 Failed to extract text:", error);
    return "";
  }
};

const captureAndAnalyzeImage = async () => {
  if (isLoading.value) return;
  isLoading.value = true;
  const canvas = document.createElement("canvas");
  canvas.width = videoElement.value.videoWidth;
  canvas.height = videoElement.value.videoHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(videoElement.value, 0, 0, canvas.width, canvas.height);
  capturedImage.value = canvas.toDataURL("image/png");
  const extractedText = await extractTextFromImage(); // Extract text from image
  sendImageToOpenAI(extractedText);
};

function completeSteps(action) {
  action?.stepNumbers?.forEach((stepNumber) => {
    planActionsModel.value[stepNumber] = 'completed';
  });
  savePlanActions();
}

function processPlanAction(action) {
  try {
    // Remove markdown code fences if present
    const markdownRegex = /```(?:json)?\s*([\s\S]*?)\s*```/;
    const match = action.match(markdownRegex);
    let jsonString = match ? match[1] : action.trim();

    // Additional check to ensure jsonString starts and ends with braces
    if (!jsonString.startsWith("{") || !jsonString.endsWith("}")) {
      throw new Error("Invalid JSON format after removing markdown.");
    }

    const parsedAction = JSON.parse(jsonString);
    if (parsedAction.error) {
      throw new Error(parsedAction.error);
    } else if (parsedAction.stepNumbers == null || parsedAction.stepNumbers == undefined) {
      throw new Error("AI did not provide stepNumbers");
    }
    completeSteps(parsedAction);
    const nextStep = findNextStep();
    if (nextStep.everyStepCompleted) {
      const query = `This plan has been completed. Briefly congratulate the user.`;
      realtimeConnection.value.askForResponse(query);
      return;
    }
    const query = `The user has completed step(s) ${parsedAction.stepNumbers} in the plan. Read aloud to the user the next step: ${nextStep.text}`;
    realtimeConnection.value.askForResponse(query);
  } catch (error) {
    const errorMessage = `😢 AI failed: ${error}`;
    console.error(errorMessage);
    analysis.value += `\n${errorMessage}`;
  }
}

function findNextStep() {
  const planSteps = lineByLinePlan.value; // Get the full list of steps
  // Iterate through each step in order
  for (const step of planSteps) {
    // If the step's id is not among the completed steps, it is the next step
    if ((step.id !== 0) && !step.isCompleted) {
      return step;
    }
  }
  // If all steps are completed, return a default message
  return { everyStepCompleted: true, text: "All steps have been completed." };
}

const sendImageToOpenAI = async (extractedText) => {
  const imageBase64 = capturedImage.value;
  const prefix = `This is a job plan. Here's the content of the plan file:\n${props.currentPlan.content}`;
  const example_json1 = `{ "stepNumbers": [1], "error": null }"`;
  let example_json2 = `"{ "stepNumbers": [2], "error": null }"`;
  if (enforcePlanOrder.value) {
    example_json2 = `"{ "stepNumbers": [2], "error": "The operator has missed a step."`;
  }
  const example_json3 = `"{ "stepNumbers": null, "error": "Could not determine the step."`;
  const examples = `${example_json1} or ${example_json2} or ${example_json3}`;
  let command = `Based on the image provided, tell me the step number the operator has completed in JSON format as shown below: ${examples}. Be very precise and detailed, and ensure the text exactly matches what's in the image. `;
  if (extractedText) {
    command += `Here is the text we extracted from the image using Tesseract: \n\n${extractedText}\n\n`;
  }
  if (enforcePlanOrder.value) {
    const currentState = JSON.stringify(lineByLinePlan.value);
    command += `Here is a JSON model of what the user has currently completed: \n${currentState}.\n`;
    command += `If the image provided shows a completed step out of order, raise an error with a message explaining the issue. E.g., if the user does step 2 before step 1 is completed.`;
  } else {
    command += `It's okay if the step is out of order.`;
  }
  const prompt = `${prefix}\n${command}`;
  console.debug(`💬 Prompt: ${prompt}`);
  const payload = {
    model: "gpt-4o",
    response_format: {
      "type": "json_schema",
      "json_schema": {
        "name": "plan_response",
        "schema": {
          "type": "object",
          "properties": {
            "stepNumbers": {
              "type": "array",
              items: {
                type: "integer"
              }
            },
            "error": {
                "type": "string",
            }
          },
          "required": ["stepNumbers", "error"],
          "additionalProperties": false
        },
        "strict": true
      }
    },
    messages: [
      {
        role: "user",
        content: [
          {
            type: "image_url",
            image_url: { url: imageBase64 },
          },
          {
            type: "text",
            text: prompt,
          },
        ],
      },
    ],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey.value}`,
  };

  try {
    toast.add({
      severity: "info",
      summary: "Sending image for analysis",
      life: 1000,
    });
    const response = await fetch(`/openai-api/chat/completions`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) throw new Error("Failed to fetch");

    const data = await response.json();
    analysis.value = data.choices[0].message.content;
    processPlanAction(analysis.value);
    toast.add({ severity: "info", summary: "Analysis complete", life: 1000 });
  } catch (error) {
    console.error("Error:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to analyze the image.",
      life: 3000,
    });
    analysis.value = "Failed to analyze image";
  } finally {
    isLoading.value = false;
    await nextTick();
    analysisSection.value.scrollIntoView({ behavior: "smooth" });
  }
};

function clearSpecificActions(stepNumbers) {
  stepNumbers?.forEach((stepNumber) => {
    if (planActionsModel.value[stepNumber])
      delete planActionsModel.value[stepNumber];
  });
  savePlanActions();
  analysis.value = '';
}

const handleConnectionEstablished = () => {
  isRealtimeConnected.value = true;
  toast.add({
    severity: "success",
    summary: "Realtime Connected",
    detail: "Connected to OpenAI WebRTC API.",
    life: 3000,
  });
  realtimeConnection.value.addToConversation(`The user has been working on a plan. Here is the current plan: ${lineByLinePlan.value}. You will be told by the user when they complete more steps. Keep your replies succinct and to only a few words.`);
};

const handleRealtimeError = (error) => {
  realtimeError.value = error;
  if (error.message){
    toast.add({
      severity: "error",
      summary: "Realtime Connection Error",
      detail: error.message,
      life: 5000,
    });
  }
  console.error("🛑 Realtime Connection Error:", error);
};

const goBackToPlans = () => {
  emit('back-to-plans');
};
</script>

<style scoped>
.plan-viewer {
  width: 100%;
}

.video-feeds {
  display: flex;
  gap: 10px;
  width: 100%;
}

.camera-feed,
.current-image {
  flex: 1;
}

.processed-image,
.video-element {
  width: 100%;
  height: 100%;
}

.camera-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #666;
}

.loading-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
}

.loading-section span {
  display: inline-block;
  animation: throb 1.5s ease-in-out infinite;
}

.completed-step {
  text-decoration: line-through;
}

.plan-display {
  white-space: pre-wrap;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 1rem;
}

.plan-display-section {
  margin-bottom: 1rem;
}

.analysis-section {
  margin-top: 1rem;
}

@keyframes throb {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}
</style>
