export default class RealtimeConnection {
    constructor({
      apiKey,
      onEventReceived = () => {},
      onConnectionEstablished = () => {},
      onError = () => {},
      onCompleteSteps = () => {},
      onClearAll = () => {},
      onClearSpecificSteps = () => {},
      onAddStep = () => {},
      onAskClarification = () => {},
      handleConnectionEstablished = null
    }) {
      this.apiKey = apiKey;
      this.onEventReceived = onEventReceived;
      this.onConnectionEstablished = handleConnectionEstablished || onConnectionEstablished;
      this.onError = onError;
      this.onCompleteSteps = onCompleteSteps;
      this.onClearAll = onClearAll;
      this.onClearSpecificSteps = onClearSpecificSteps;
      this.onAddStep = onAddStep;
      this.onAskClarification = onAskClarification;
      this.peerConnection = null;
      this.dataChannel = null;
      this.audioElement = null;
    }
  
    async init() {
      try {
        // Create a peer connection
        this.peerConnection = new RTCPeerConnection();
  
        // Set up to play remote audio from the model
        this.audioElement = document.createElement("audio");
        this.audioElement.autoplay = true;
        this.peerConnection.ontrack = (e) => {
          this.audioElement.srcObject = e.streams[0];
        };
  
        // Add local audio track for microphone input in the browser
        const ms = await navigator.mediaDevices.getUserMedia({ audio: true });
        ms.getTracks().forEach((track) => this.peerConnection.addTrack(track, ms));
  
        // Set up data channel for sending and receiving events
        this.dataChannel = this.peerConnection.createDataChannel("oai-events");
        this.dataChannel.addEventListener("message", (e) => {
          const realtimeEvent = JSON.parse(e.data);
          this.handleRealtimeEvent(realtimeEvent);
        });
  
        // Start the session using the Session Description Protocol (SDP)
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
  
        const baseUrl = "https://api.openai.com/v1/realtime";
        const model = "gpt-4o-realtime-preview-2024-12-17";
  
        const response = await fetch(`${baseUrl}?model=${model}`, {
          method: "POST",
          body: this.peerConnection.localDescription.sdp,
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "Content-Type": "application/sdp",
          },
        });
  
        if (!response.ok) {
          throw new Error(`SDP Exchange failed with status ${response.status}`);
        }
  
        const answerSdp = await response.text();
        const answer = { type: "answer", sdp: answerSdp };
        await this.peerConnection.setRemoteDescription(answer);
  
        this.dataChannel.addEventListener("open", () => {
          this.onConnectionEstablished();
          // add tools to update relay plan
          this.addToolsToSession();
        });
  
        console.debug("🤝 WebRTC connection established.");
      } catch (error) {
        console.error("🛑 RealtimeConnection Init Error:", error);
        this.onError(error);
      }
    }

    addToolsToSession() {
      const sessionUpdateEvent = {
        type: "session.update",
        session: {
            tools: [
              {
                type: "function",
                name: "complete_steps",
                description: "Completes specific steps in the process.",
                parameters: {
                  type: "object",
                  properties: {
                    stepNumbers: {
                      type: "array",
                      items: {
                        type: "integer"
                      }
                    }
                  },
                  required: ["stepNumbers"]
                }
              },
              {
                type: "function",
                name: "clear_steps",
                description: "Clears specific steps in the process.",
                parameters: {
                  type: "object",
                  properties: {
                    stepNumbers: {
                      type: "array",
                      items: {
                        type: "integer"
                      }
                    }
                  },
                  required: ["stepNumbers"]
                }
              },
                {
                    type: "function",
                    name: "clear_all",
                    description: "Clears all the steps."
                }
            ]
        }
    };
    this.sendEvent(sessionUpdateEvent);
    }

    addToConversation(message) {
      const conversationEvent ={
        "type": "conversation.item.create",
        "item": {
            "type": "message",
            "role": "user",
            "content": [
                {
                    "type": "input_text",
                    "text": message
                }
            ]
        }
      };
      this.sendEvent(conversationEvent);
    }

    askForResponse(message) {
      const conversationEvent ={
        "type": "response.create",
        "response": {
          "instructions": message,
        }
      };
      this.sendEvent(conversationEvent);
    }
  
    sendEvent(event) {
      if (this.dataChannel && this.dataChannel.readyState === "open") {
        this.dataChannel.send(JSON.stringify(event));
      } else {
        console.warn("Data channel is not open. Cannot send event:", event);
      }
    }
  
    close() {
      if (this.dataChannel) {
        this.dataChannel.close();
      }
      if (this.peerConnection) {
        this.peerConnection.close();
      }
      if (this.audioElement) {
        this.audioElement.srcObject = null;
      }
      console.debug("RealtimeConnection closed.");
    }

    handleRealtimeEvent(event) {
      console.debug("📲 Received realtime event:", event);
      if (event?.type === "response.done") {
        console.debug("🚢 Agent thinks it's done...");
        const eventOutput = event?.response?.output[0];
        if (eventOutput?.type === "function_call") {
          const functionName = eventOutput.name;
          if (functionName === "complete_steps") {
            try {
              const action = JSON.parse(event?.response?.output[0].arguments);
              this.onCompleteSteps(action);
            } catch (error) {
              console.error(`🛑 Error parsing function ${functionName} arguments:`, error);
            }
          } else if (functionName === "clear_all") {
            this.onClearAll()
          } else if (functionName === "clear_steps") {
            try {
              const action = JSON.parse(event?.response?.output[0].arguments);
              this.onClearSpecificSteps(action.stepNumbers);
            } catch (error) {
              console.error(`🛑 Error parsing function ${functionName} arguments:`, error);
            }
          } else if (functionName === "add_step") {
            try {
              const action = JSON.parse(event?.response?.output[0].arguments);
              this.onAddStep(action);
            } catch (error) {
              console.error(`🛑 Error parsing function ${functionName} arguments:`, error);
            }
          } else if (functionName === "ask_clarification") {
            try {
              const action = JSON.parse(event?.response?.output[0].arguments);
              this.onAskClarification(action);
            } catch (error) {
              console.error(`🛑 Error parsing function ${functionName} arguments:`, error);
            }
          } else {
            console.warn(`🤷‍♂️ Unhandled function call: ${functionName}`);
          }
        }
      }
    }
  }
