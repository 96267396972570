<template>
  <div class="plan-execution">
    <div class="header-with-actions">
      <h2>{{ mode === 'authoring' ? 'My Plans' : 'Available Plans' }}</h2>
      <Button 
        v-if="mode === 'authoring'" 
        label="Create New Plan" 
        icon="pi pi-plus" 
        @click="createPlan" 
        class="p-button-success"
      />
    </div>
    
    <div class="plans-list">
      <Card v-for="(plan, index) in availablePlans" :key="index" class="plan-card">
        <template #title>{{ plan.title }}</template>
        <template #content>
          <div class="plan-preview">{{ getPlanPreview(plan.content) }}</div>
          <div class="plan-meta">
            <span class="plan-date">Created: {{ formatDate(plan.createdAt) }}</span>
            <span v-if="plan.updatedAt" class="plan-date">Updated: {{ formatDate(plan.updatedAt) }}</span>
          </div>
          <div class="plan-actions">
            <Button 
              label="Execute" 
              icon="pi pi-play" 
              @click="selectPlan(plan)" 
              class="p-button-success" 
            />
            <Button 
              v-if="mode === 'authoring'" 
              label="Edit" 
              icon="pi pi-pencil" 
              @click="editPlan(plan)" 
              class="p-button-secondary" 
            />
            <Button 
              v-if="mode === 'authoring'" 
              label="Delete" 
              icon="pi pi-trash" 
              @click="confirmDeletePlan(plan)" 
              class="p-button-danger" 
            />
          </div>
        </template>
      </Card>
      
      <div v-if="availablePlans.length === 0" class="no-plans">
        <div class="empty-state">
          <i class="pi pi-file-o empty-icon"></i>
          <h3>No Plans Found</h3>
          <p v-if="mode === 'authoring'">Create your first plan to get started!</p>
          <p v-else>No plans are available for execution.</p>
          <Button 
            v-if="mode === 'authoring'" 
            label="Create New Plan" 
            icon="pi pi-plus" 
            @click="createPlan" 
            class="p-button-success"
          />
        </div>
      </div>
    </div>
    
    <Dialog v-model:visible="deleteDialogVisible" header="Confirm Deletion" :style="{width: '450px'}">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle" style="font-size: 2rem; color: var(--red-500);"></i>
        <span>Are you sure you want to delete <strong>{{ planToDelete?.title }}</strong>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="deleteDialogVisible = false" class="p-button-text"/>
        <Button label="Yes" icon="pi pi-check" @click="deletePlan" class="p-button-danger" />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import defaultPlan from '@/assets/default_plan.md?raw';

const props = defineProps({
  mode: {
    type: String,
    default: 'authoring', // 'authoring' or 'execution'
    required: true
  },
  selectedPlanId: {
    type: String,
    default: null
  }
});

const emit = defineEmits(['plan-selected', 'edit-plan', 'create-plan']);
const toast = useToast();

const availablePlans = ref([]);
const deleteDialogVisible = ref(false);
const planToDelete = ref(null);

onMounted(() => {
  loadPlansFromStorage();
});

// Watch for mode changes to refresh plans
watch(() => props.mode, () => {
  loadPlansFromStorage();
});

const loadPlansFromStorage = () => {
  // Get plans from local storage or initialize with default plan
  const storedPlans = localStorage.getItem('plancheck-plans');
  
  if (storedPlans) {
    availablePlans.value = JSON.parse(storedPlans);
    // Sort plans by updated date (newest first)
    availablePlans.value.sort((a, b) => {
      const dateA = a.updatedAt || a.createdAt;
      const dateB = b.updatedAt || b.createdAt;
      return new Date(dateB) - new Date(dateA);
    });
  } else {
    // Initialize with default plan
    const defaultPlanObj = {
      id: 'default',
      title: 'Electric Light and Switch Project',
      content: defaultPlan,
      createdAt: new Date().toISOString()
    };
    
    availablePlans.value = [defaultPlanObj];
    savePlansToStorage();
  }
};

const savePlansToStorage = () => {
  localStorage.setItem('plancheck-plans', JSON.stringify(availablePlans.value));
};

const getPlanPreview = (content) => {
  // Return first 100 characters of the plan as a preview
  return content.length > 100 ? content.substring(0, 100) + '...' : content;
};

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' 
  });
};

const selectPlan = (plan) => {
  emit('plan-selected', plan);
};

const editPlan = (plan) => {
  emit('edit-plan', plan);
};

const createPlan = () => {
  emit('create-plan');
};

const confirmDeletePlan = (plan) => {
  planToDelete.value = plan;
  deleteDialogVisible.value = true;
};

const deletePlan = () => {
  if (!planToDelete.value) return;
  
  // Remove plan from array
  availablePlans.value = availablePlans.value.filter(p => p.id !== planToDelete.value.id);
  
  // Save updated plans to storage
  savePlansToStorage();
  
  // Close dialog
  deleteDialogVisible.value = false;
  
  // Show success message
  toast.add({
    severity: 'success',
    summary: 'Plan Deleted',
    detail: `"${planToDelete.value.title}" has been deleted.`,
    life: 3000
  });
  
  planToDelete.value = null;
};
</script>

<style scoped>
.plan-execution {
  width: 100%;
}

.header-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.plans-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.plan-card {
  height: 100%;
  transition: transform 0.2s, box-shadow 0.2s;
}

.plan-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plan-preview {
  white-space: pre-line;
  overflow: hidden;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #555;
  min-height: 60px;
}

.plan-meta {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  color: #777;
  margin-bottom: 1rem;
}

.plan-date {
  margin-bottom: 0.25rem;
}

.plan-actions {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.no-plans {
  grid-column: 1 / -1;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
}

.empty-icon {
  font-size: 3rem;
  color: #ccc;
  margin-bottom: 1rem;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
}
</style>
