<template>
  <div class="main-wrapper">
    <Toast />
    <div class="main-container">
      <div class="header-section">
        <div class="app-header">
          <h1>PlanCheck</h1>
          <div class="mode-toggle">
            <Button 
              :class="{ 'p-button-outlined': activeMode !== 'authoring' }"
              :disabled="activeMode === 'authoring'"
              icon="pi pi-pencil" 
              label="Authoring" 
              @click="setMode('authoring')" 
            />
            <Button 
              :class="{ 'p-button-outlined': activeMode !== 'execution' }"
              :disabled="activeMode === 'execution'"
              icon="pi pi-play" 
              label="Execution" 
              @click="setMode('execution')" 
            />
          </div>
        </div>
      </div>
      
      <!-- Main content area -->
      <div class="content-section">
        <!-- Authoring Mode -->
        <div v-if="activeMode === 'authoring'" class="authoring-mode">
          <!-- Plan List in Authoring Mode -->
          <PlanExecution 
            v-if="authoringView === 'plans'" 
            mode="authoring"
            @plan-selected="handlePlanSelected" 
            @edit-plan="handleEditPlan"
            @create-plan="handleCreatePlan"
          />
          
          <!-- Plan Editor -->
          <PlanEditor 
            v-if="authoringView === 'editor'" 
            :plan="currentPlan" 
            @save="handlePlanSaved" 
            @cancel="authoringView = 'plans'"
          />
        </div>
        
        <!-- Execution Mode -->
        <div v-if="activeMode === 'execution'" class="execution-mode">
          <!-- Plan List in Execution Mode -->
          <PlanExecution 
            v-if="executionView === 'plans'" 
            mode="execution"
            @plan-selected="handleExecutePlan" 
          />
          
          <!-- Plan Viewer (execution with camera) -->
          <PlanViewer 
            v-if="executionView === 'viewer'" 
            :currentPlan="currentPlan" 
            @back-to-plans="executionView = 'plans'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Toast from "primevue/toast";
import Button from "primevue/button";
import PlanExecution from "@/components/PlanExecution.vue";
import PlanEditor from "@/components/PlanEditor.vue";
import PlanViewer from "@/components/PlanViewer.vue";

// Application state
const activeMode = ref('authoring'); // 'authoring' or 'execution'
const authoringView = ref('plans'); // 'plans' or 'editor'
const executionView = ref('plans'); // 'plans' or 'viewer'
const currentPlan = ref(null);

// Set active mode
const setMode = (mode) => {
  activeMode.value = mode;
  // Reset views when switching modes
  if (mode === 'authoring') {
    authoringView.value = 'plans';
  } else {
    executionView.value = 'plans';
  }
};

// Handle plan selection in authoring mode
const handlePlanSelected = (plan) => {
  currentPlan.value = plan;
  // Switch to execution mode and view the selected plan
  activeMode.value = 'execution';
  executionView.value = 'viewer';
};

// Handle edit plan request
const handleEditPlan = (plan) => {
  currentPlan.value = plan;
  authoringView.value = 'editor';
};

// Handle create new plan
const handleCreatePlan = () => {
  currentPlan.value = {
    id: null,
    title: '',
    content: '',
    createdAt: null
  };
  authoringView.value = 'editor';
};

// Handle execute plan in execution mode
const handleExecutePlan = (plan) => {
  currentPlan.value = plan;
  executionView.value = 'viewer';
};

// Handle plan saved
const handlePlanSaved = (updatedPlan) => {
  // Get all plans from local storage
  const storedPlans = localStorage.getItem('plancheck-plans');
  let plans = storedPlans ? JSON.parse(storedPlans) : [];
  
  // Find if the plan already exists
  const existingPlanIndex = plans.findIndex(p => p.id === updatedPlan.id);
  
  if (existingPlanIndex >= 0) {
    // Update existing plan
    plans[existingPlanIndex] = updatedPlan;
  } else {
    // Add new plan
    plans.push(updatedPlan);
  }
  
  // Save back to local storage
  localStorage.setItem('plancheck-plans', JSON.stringify(plans));
  
  // Go back to plans view
  authoringView.value = 'plans';
};
</script>

<style scoped>
.main-wrapper {
  padding: 5px;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.mode-toggle {
  display: flex;
  gap: 10px;
}

.content-section {
  width: 100%;
}

.authoring-mode {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
}

.execution-mode {
  background-color: #f0f4f8;
  padding: 15px;
  border-radius: 8px;
}
</style>
